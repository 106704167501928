<template>
  <div class="recentApplicationsContainer">
    <div v-if="canViewAll && !isLoading">
      <div
        v-for="recentApplication in recentApplicationList"
        :key="recentApplication.applicationID"
      >
        <application :application="recentApplication"></application>
      </div>
    </div>

    <div class="flex-col flex-center mb-5 mt-5" style="width: 100%" v-if="isLoading">
      <b-spinner class="base-header-text m0-auto"></b-spinner>
    </div>
    <div v-if="canViewAll && !isLoading" class="justify-center">
      <plain-button id="start-new-app" class="mt-2 mb-2" @click="createApplication"
        >Start New Application</plain-button
      >
      <plain-button class="mt-2 mb-2" @click="goToApplicationsList">View All</plain-button>
    </div>
    <div v-if="!canViewAll && !isLoading" class="resources-empty-state">
      <b-button class="login-blue" @click="createApplication">
        <span class="label">{{ "Start Application" }}</span>
      </b-button>
    </div>
  </div>
</template>
<style scoped>
.recentApplicationsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
#start-new-app {
  border-right: 1.5px solid #d2d2d2;
  margin-right: 1rem;
  padding-right: 1rem;
}
</style>
<script>
import Application from "./Application.vue";
import PlainButton from "../../Common/PlainButton.vue";
import ApplicationMixin from "../../../mixins/ApplicationMixin.vue";

export default {
  props: {
    recentApplications: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    application: Application,
    "plain-button": PlainButton,
  },
  mixins: [ApplicationMixin],
  methods: {
    async createApplication() {
      const { companyID = "" } = JSON.parse(localStorage.getItem("currentCompany"));
      await this.startApplication(companyID);
      if (this.statusCode && this.statusCode === 200) {
        this.$router.push({
          name: "ApplicationLanding",
          params: { applicationID: this.applicationID },
        });
      }
    },
    goToApplicationsList() {
      this.$router.push({ name: "Applications" });
    },
  },
  computed: {
    recentApplicationList() {
      return this.recentApplications.slice(0, 3);
    },
    canViewAll() {
      return this.recentApplications.length > 0;
    },
  },
};
</script>
