<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      employee: {},
      processing: false,
    };
  },
  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
    ]),
    async getEmployeeByID(employeeID) {
      await axios
        .get(`${WebAPIUrl}/api/authorization/employee/${employeeID}`)
        .then((result) => {
          this.employee = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getCurrentUser() {
      await axios
        .get(`${WebAPIUrl}/api/user-profile`)
        .then((result) => {
          this.employee = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async updateCurrentUser({ firstName, lastName }) {
      this.processing = true;
      await axios
        .put(`${WebAPIUrl}/api/user-profile?firstName=${firstName}&lastName=${lastName}`)
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) {
            this.setInfoMessage(result.data);
          }
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
      this.processing = false;
    },
  },
};
</script>
