<template>
  <div>
    <p class="baseValueText" :class="valueClass">{{ value }}</p>
    <p class="baseText" :class="textClass">{{ name }}</p>
  </div>
</template>
<style lang="scss" scoped>
div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.baseValueText {
  color: #005175;
  font-weight: 800;
  margin: 0;
}
.smValueText {
  font-size: 1.5rem;
}
.mdValueText {
  font-size: 2rem;
}
.lgValueText {
  font-size: 2.5rem;
}
.autoValueText {
  @media (min-width: 2249px) {
    font-size: 3.25rem;
  }
  @media (max-width: 2248px) {
    font-size: 2.5rem;
  }
  @media (max-width: 724px) {
    font-size: 2rem;
  }
  @media (max-width: 639px) {
    font-size: 1.5rem;
  }
}

.baseText {
  color: #737373;
  font-weight: 700;
  padding: 0;
  margin: 0;
}
.smText {
  font-size: 0.7rem;
}
.mdText {
  font-size: 0.75rem;
}
.lgText {
  font-size: 0.8rem;
}
.autoText {
  @media (min-width: 2249px) {
    font-size: 1rem;
  }
  @media (max-width: 2248px) {
    font-size: 0.8rem;
  }
  @media (max-width: 724px) {
    font-size: 0.75rem;
  }
  @media (max-width: 639px) {
    font-size: 0.7rem;
  }
}
</style>
<script>
export default {
  props: ["value", "name", "variant"],
  computed: {
    valueClass() {
      if (this.variant === "sm") {
        return "smValueText";
      } else if (this.variant === "md") {
        return "mdValueText";
      } else if (this.variant === "lg") {
        return "lgValueText";
      }

      return "autoValueText";
    },
    textClass() {
      if (this.variant === "sm") {
        return "smText";
      } else if (this.variant === "md") {
        return "mdText";
      } else if (this.variant === "lg") {
        return "lgText";
      }

      return "autoText";
    },
  },
};
</script>
