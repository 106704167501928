<template>
  <section>
    <b-row>
      <b-col sm="12" xl="8">
        <!-- Dashboard Header, Current Applications -->
        <b-row class="mb-4">
          <b-col>
            <!-- Dashboard Header -->
            <div>
              <h3 class="bold-header-text app-header">Welcome {{ userName }}</h3>
              <p class="small-subtitle">
                Hope you have a great day. Let's see what tasks are available.
              </p>
            </div>
            <!-- Current Applications -->
            <b-card class="dashboard-card shadow-sm mr-t-4">
              <div>
                <div class="application-data-block-container mb-3">
                  <data-block
                    class="dataBlock"
                    :value="applicationDashboard.inProgress"
                    name="In Progress"
                    variant="auto"
                  ></data-block>
                  <div class="dataBlockSeparator"></div>
                  <data-block
                    class="dataBlock"
                    :value="applicationDashboard.missingItems"
                    name="Missing Items"
                    variant="auto"
                  ></data-block>
                  <div class="dataBlockSeparator"></div>
                  <data-block
                    class="dataBlock"
                    :value="applicationDashboard.notSubmitted"
                    name="Not Submitted"
                    variant="auto"
                  ></data-block>
                  <div class="dataBlockSeparator"></div>
                  <data-block
                    class="dataBlock"
                    :value="applicationDashboard.completed"
                    name="Completed"
                    variant="auto"
                  ></data-block>
                </div>
                <recent-applications
                  :recentApplications="applicationDashboard.applications"
                  :isLoading="applicationCardLoading"
                ></recent-applications>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <!-- Branch Locations -->
        <b-row class="mb-4">
          <b-col>
            <achc-additional-sites
              :additionalSites="additionalSites"
              :isLoading="additionalSitesCardLoading"
            ></achc-additional-sites>
          </b-col>
        </b-row>
        <!-- News, ACHCU Links, Financial Overview -->
        <b-row>
          <b-col lg="12" xl="6">
            <financial-overview-card
              v-show="false"
              totalAmountDue="$389.99"
              nextPaymentDue="28 days"
              class="mb-4"
            ></financial-overview-card>
          </b-col>
        </b-row>
      </b-col>
      <!-- AA, Branding, Tutorial -->
      <b-col lg="12" xl="4">
        <current-account-advisor
          class="mb-4 card-adjustment"
          :accountAdvisor="employee"
        ></current-account-advisor>
        <!-- <achc-branding-assets-card class="mb-4"></achc-branding-assets-card> -->
        <video-card
          v-show="true"
          class="mb-4 shadow-sm"
          title="Why ACHC?"
          url="https://www.youtube.com/embed/3rNHzg-eT7M"
        ></video-card>
        <achcu-card class="mb-4"></achcu-card>
        <recent-news class="mb-4" :maxArticles="3"></recent-news>
      </b-col>
    </b-row>
  </section>
</template>
<style lang="scss" scoped>
.dataBlockSeparator {
  @media (min-width: 640px) {
    width: 1px;
    min-height: 85px;
  }
  @media (max-width: 639px) {
    width: 90%;
    height: 1px;
  }
  background-color: #d2d2d2;
}
.dataBlock {
  flex-basis: 2;
}
.card-adjustment {
  margin-top: 4.4rem;
}
</style>
<script>
// Components
// import ACHCBrandingAssetsCard from "../../components/App/Dashboard/ACHCBrandingAssetsCard.vue";
import ACHCUCard from "../../components/App/Dashboard/ACHCUCard.vue";
import AdditionalSitesCard from "../../components/App/Dashboard/AdditionalSitesCard.vue";
import CurrentAccountAdvisor from "../../components/App/Dashboard/CurrentAccountAdvisor.vue";
import DataBlock from "../../components/Common/DataBlock.vue";
import FinancialOverviewCard from "../../components/App/Dashboard/FinancialOverviewCard.vue";
import RecentApplications from "../../components/App/Dashboard/RecentApplications.vue";
import RecentNews from "../../components/App/Dashboard/RecentNews.vue";
import VideoCard from "../../components/Common/VideoCard.vue";
import jwt_decode from "jwt-decode";
import { bus } from "../../main";
// Mixins
import ApplicationMixin from "../../mixins/ApplicationMixin.vue";
import AuthorizationMixin from "../../mixins/AuthorizationMixin.vue";
import CompanyMixin from "../../mixins/CompanyMixin.vue";
import { mapGetters } from "vuex";

// Test Data
// import applicationData from "../../data/applicationData";
import recentNewsData from "../../data/recentNewsData";

export default {
  data() {
    return {
      recentNews: recentNewsData,
      userName: "Guest",
      applicationCardLoading: false,
      additionalSitesCardLoading: false,
      company: {},
    };
  },
  mounted() {
    const userDetail = localStorage.getItem("userToken");
    if (userDetail) {
      const { FirstName, LastName, EmailAddress } = jwt_decode(userDetail);
      this.userName = `${FirstName} ${LastName}`;
      localStorage.setItem(
        "loggedUserData",
        JSON.stringify({
          firstname: FirstName,
          lastname: LastName,
          email: EmailAddress,
        })
      );
    } else {
      this.userName = "Guest";
    }
  },
  components: {
    // "achc-branding-assets-card": ACHCBrandingAssetsCard,
    "achcu-card": ACHCUCard,
    "achc-additional-sites": AdditionalSitesCard,
    "current-account-advisor": CurrentAccountAdvisor,
    "data-block": DataBlock,
    "financial-overview-card": FinancialOverviewCard,
    "recent-applications": RecentApplications,
    "recent-news": RecentNews,
    "video-card": VideoCard,
  },
  mixins: [ApplicationMixin, AuthorizationMixin, CompanyMixin],
  async created() {
    bus.$emit("showFullpageOverlay", {
      showLayover: true,
    });

    this.company = JSON.parse(localStorage.getItem("currentCompany"));

    this.applicationCardLoading = true;
    this.additionalSitesCardLoading = true;

    await this.getApplicationDashboardInfo(this.company.companyID);
    this.applicationCardLoading = false;

    await this.getDashboardAdditionalSites(this.company.companyID, true);
    this.additionalSitesCardLoading = false;
    bus.$emit("showFullpageOverlay", {
      showLayover: false,
    });
    // Testing company info with parent
    // localStorage.setItem("CompanyID", 79043);
    // Testing company info with branch
    // localStorage.setItem("CompanyID", 78754);
  },
  methods: {},
  computed: {
    ...mapGetters({
      getAdditionalSites: "getAdditionalSites",
    }),
    additionalSites() {
      return this.getAdditionalSites;
    },
  },
};
</script>
