<template>
  <b-card :title="title" class="dashboard-card">
    <b-row>
      <b-col>
        <iframe
          :src="url"
          title="Media Player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          frameborder="0"
          style="padding: 0 8px 8px; display: block; width: 100%; aspect-ratio: 16/9"
        ></iframe>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
export default {
  props: ["title", "url"],
};
</script>
