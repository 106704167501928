<template>
  <b-card class="thin-gray-border-rounded shadow-sm">
    <div class="application-container">
      <div class="dashboard-subcard-icon">
        <img :src="applicationIcon" />
      </div>

      <div class="data-block">
        <p class="bold-gray-text text-center">Application</p>
        <p class="data-value text-center">{{ application.applicationID }}</p>
      </div>

      <div class="application-data-separator"></div>

      <div class="data-block">
        <p class="bold-gray-text text-center">
          <span class="mobile-info-text">Application Type:</span>{{ application.type }}
        </p>
      </div>

      <div class="application-data-separator"></div>

      <div class="data-block">
        <p :class="applicationStatusTextClass">
          <span class="mobile-info-text">Status:</span>{{ application.status }}
        </p>
      </div>

      <div class="application-data-separator"></div>

      <div class="data-block application-button-block">
        <button
          class="application-button"
          :class="applicationButtonStatus"
          @click="onButtonClick()"
        >
          {{ applicationButtonText }}
        </button>
      </div>
    </div>
  </b-card>
</template>
<style lang="scss" scoped>
.application-container {
  display: flex;
  @media (min-width: 600px) {
    flex-direction: row;
  }
  @media (max-width: 599px) {
    flex-direction: column;
  }
  justify-content: space-evenly;
  align-items: center;
  gap: 10px;
}
.application-data-separator {
  @media (min-width: 600px) {
    width: 1px;
    min-height: 55px;
  }
  @media (max-width: 599px) {
    width: 100%;
    height: 1px;
  }
  background-color: #d2d2d2;
}
.data-block {
  @media (min-width: 600px) {
    flex-basis: 23%;
  }
  @media (max-width: 599px) {
    flex-basis: 100%;
  }
}
.mobile-info-text {
  color: #737373;
  @media (min-width: 600px) {
    display: none;
  }
  @media (max-width: 599px) {
    margin-right: 6px;
  }
}
.application-button-block {
  display: flex;
  justify-content: center;
}
.application-button {
  color: #ffffff;
  border-radius: 30px;
  border: none;
  font-size: 0.9rem;
  font-weight: 700;
  @media (min-width: 600px) {
    padding: 10px 25%;
  }
  @media (max-width: 599px) {
    padding: 10px 40px;
  }
  transition: background-color 0.2s ease;
}
.active-application {
  background-color: #005175;
}
.active-application:hover {
  background-color: #74a2bf;
}
.completed-application {
  background-color: #74a2bf;
}
.completed-application:hover {
  background-color: #dee6ed;
}
</style>
<script>
export default {
  props: ["application"],
  methods: {
    onButtonClick() {
      this.$router.push({
        name: "ApplicationLanding",
        params: { applicationID: this.application.applicationID },
      });
    },
  },
  computed: {
    applicationStatusTextClass() {
      return this.application.applicationStatusID === 1
        ? "bold-gray-text text-center"
        : "bold-red-text text-center";
    },
    applicationButtonStatus() {
      return this.application.applicationStatusID === 1
        ? "completed-application"
        : "active-application";
    },
    applicationButtonText() {
      return this.application.applicationStatusID === 1 ? "Review" : "Edit";
    },
    applicationIcon() {
      return this.application.applicationStatusID === 1
        ? require("../../../assets/img/app/app-ico-light-blue-01.svg")
        : require("../../../assets/img/app/app-ico-blue-01.svg");
    },
  },
};
</script>
