<template>
  <b-card class="dashboard-card shadow-sm" title="Need Help?">
    <div class="aa-container">
      <div class="d-none d-sm-block">
        <img src="../../../assets/img/app/account-advisor-ico-01.svg" class="aa-icon" />
      </div>
      <div class="aa-info">
        <p class="base-gray-text">{{ accountAdvisor.displayName }}</p>
        <p class="base-gray-text">{{ accountAdvisor.emailAddress }}</p>
        <p class="base-gray-text">(919) 785-1214 ext: {{ accountAdvisor.extension || "None" }}</p>
        <p class="base-gray-text">Fax: (919) 785-3011</p>
      </div>
    </div>
  </b-card>
</template>
<style scoped lang="scss">
.aa-icon {
  width: 6.25rem;
  height: 6.25rem;
  aspect-ratio: 1/1;

  @media (max-width: 1450px) {
    display: none;
  }
}
.aa-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.aa-container {
  display: flex;
  justify-content: flex-start;
  padding: 1rem;
  gap: 2rem;
}
</style>
<script>
import CompanyMixin from "../../../mixins/CompanyMixin.vue";

export default {
  mixins: [CompanyMixin],
  async created() {
    var getCompany = localStorage.getItem("currentCompany");
    if (getCompany) {
      var userCompany = JSON.parse(getCompany);
      if (userCompany.accountManagerGUID) {
        await this.getAdvisorDetails(userCompany.accountManagerGUID);
      }
    }
  },
};
</script>
