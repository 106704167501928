<template>
  <b-card title="Sites" class="dashboard-card shadow-sm">
    <div class="additional-sites-quick-view-container" v-if="!isLoading">
      <additional-site
        v-for="(branch, index) in recentAdditionalSites"
        :key="`${branch.companyID}-${index}`"
        :branch="branch"
        class="additional-site-card"
      ></additional-site>
      <div v-if="isAdditionalSitesEmpty" class="image-empty-state">
        <!-- This icon doesn't exist but I think it would be nice to have an image attached to the empty state -->
        <!-- Plus we need a company icon for the branch location card anyways -->
        <img src="../../../assets/img/app/additional-site-icon.svg" />
        You currently have no additional sites
        <button class="base-button" @click="goToAddAdditionalSite">Add Additional Site</button>
      </div>
    </div>

    <div class="flex-col flex-center mb-5 mt-5" style="width: 100%" v-if="isLoading">
      <b-spinner class="base-header-text m0-auto"></b-spinner>
    </div>

    <plain-button
      class="mt-2 mb-2"
      @click="goToAdditionalSites"
      v-if="!isAdditionalSitesEmpty && !isLoading"
      >View All</plain-button
    >
  </b-card>
</template>
<script>
import AdditionalSite from "./AdditionalSite.vue";
import PlainButton from "../../Common/PlainButton.vue";

export default {
  props: ["additionalSites", "isLoading"],
  components: {
    "additional-site": AdditionalSite,
    "plain-button": PlainButton,
  },
  computed: {
    recentAdditionalSites() {
      if (this.additionalSites === undefined) {
        return [];
      }
      return this.additionalSites.slice(0, 3);
    },
    isAdditionalSitesEmpty() {
      return this.additionalSites && this.additionalSites.length === 1;
    },
  },
  methods: {
    goToAdditionalSites() {
      this.$router.push({ name: "AdditionalSites" });
    },
    goToAddAdditionalSite() {
      this.$router.push({ name: "AddAdditionalSite" });
    },
  },
};
</script>
<style scoped>
.additional-site-card {
  max-width: 150px;
  min-height: 280px;
  /* max-height: 280px; */
  position: relative;
}
.additional-sites-quick-view-container {
  flex-direction: row;
}
</style>
