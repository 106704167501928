<template>
  <b-card title="Financial Overview" class="dashboard-card shadow-sm">
    <div class="detailsContainer">
      <data-block
        class="financialDataBock"
        :value="totalAmountDue"
        name="Total Amount Due"
        variant="sm"
      ></data-block>
      <div class="vertical-separator"></div>
      <data-block
        class="financialDataBock"
        :value="nextPaymentDue"
        name="Till Next Payment"
        variant="sm"
      ></data-block>
      <div class="horizontal-separator"></div>
      <p class="descriptionText">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
        labore et dolore magna aliqua. Lorem ipsum dolor sit amet.
      </p>
    </div>

    <plain-button class="mt-2 mb-2" @click="goToPayments">Go to Payments</plain-button>
  </b-card>
</template>
<style lang="scss" scoped>
.descriptionText {
  color: #979797;
  font-size: 0.7rem;
  flex-basis: 100%;
  margin: 0 4px;
}
.detailsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.buttonContainer {
  display: flex;
  justify-content: center;
}
.financialDataBock {
  @media (min-width: 435px) {
    flex-basis: 49.5%;
  }
  @media (max-width: 434px) {
    flex-basis: 100%;
  }
}
</style>
<script>
import DataBlock from "../../Common/DataBlock.vue";
import PlainButton from "../../Common/PlainButton.vue";

export default {
  props: {
    totalAmountDue: {
      default: "$0.0",
    },
    nextPaymentDue: {
      default: "0 days",
    },
  },
  methods: {
    goToPayments() {
      // this.$router.push({ name: "payments" });
    },
  },
  components: {
    "data-block": DataBlock,
    "plain-button": PlainButton,
  },
};
</script>
