<template>
  <div>
    <b-card class="thin-gray-border-rounded shadow-sm">
      <div class="additional-site-container">
        <span>
          <section class="additional-site-container-header">
            <div class="dashboard-subcard-icon mini-icon">
              <img
                v-if="branch.parentCompanyID === 0"
                src="../../../assets/img/app/main-site-ico-01.svg"
              />
              <img v-else src="../../../assets/img/app/additional-site-icon.svg" />
            </div>
            <div>
              <p class="bold-gray-text company-name">{{ branch.companyLegalName }}</p>
              <p class="data-value company-id">{{ branch.companyID }}</p>
            </div>
          </section>

          <div class="additional-site-header-separator mt-2 mb-2"></div>

          <section class="additional-site-container-body">
            <!-- <p class="bold-gray-text">Address</p>
        <p class="base-gray-text">{{ branch.companyAddress }}</p> -->

            <p class="bold-gray-text">Programs</p>
            <p class="add-base-gray-text">{{ branch.programsOfInterest }}</p>
          </section></span
        >

        <button class="additional-site-button mt-3 mb-2" @click="goToEditAdditionalSite">
          Edit
        </button>
      </div>
    </b-card>
    <!-- <div class="ribbon-1 left">Rotated Ribbon</div> -->
    <div class="ribbon-2" v-if="branch.parentCompanyID === 0">Main Site</div>
  </div>
</template>
<script>
export default {
  props: ["branch"],
  methods: {
    goToEditAdditionalSite() {
      this.$router.push(`${this.$appRoot}/company/${this.branch.companyID}/details/info`);
    },
  },
};
</script>
<style scoped>
.company-id {
  font-size: 1rem;
}
.company-name {
  font-size: 0.6rem;
  word-break: break-word;
}
.ribbon-1 {
  position: fixed;
  background: #08769b;
  box-shadow: 0 0 0 999px #08769b;
  clip-path: inset(0 -100%);
}
.left {
  inset: 0 auto auto 0;
  transform-origin: 100% 0;
  transform: translate(-29.3%) rotate(-45deg);
}
.additional-site-container {
  position: relative;
}
.ribbon-2 {
  --f: 10px; /* control the folded part*/
  --r: 15px; /* control the ribbon shape */
  --t: -9px; /* the top offset */

  position: absolute;
  inset: var(--t) calc(-1 * var(--f)) auto auto;
  padding: 0 10px var(--f) calc(10px + var(--r));
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
  background: #005175;
  font-size: 12px;
  color: white;
  box-shadow: 0 calc(-1 * var(--f)) 0 inset #0005;
}
.mini-icon img {
  height: 1.5rem;
}
.thin-gray-border-rounded {
  height: 100%;
}
</style>
