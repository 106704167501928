<template>
  <b-card class="dashboard-card shadow-sm">
    <b-row class="mb-3">
      <b-col cols="5" style="display: flex; justify-content: center; align-items: center">
        <img src="../../../assets/img/app/achcu-icon-01.svg" />
      </b-col>
      <b-col cols="7" style="display: flex; justify-content: center; align-items: center">
        <p>
          Accreditation <span style="font-weight: 700">educational resources</span> tailored to
          cover all your needs.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="lg">
        <ul class="achc-menu">
          <li>
            <a class="plainButton" target="_blank" href="https://achcu.com/education/">Education</a>
          </li>
          <li>
            <a class="plainButton" target="_blank" href="https://achcu.com/workshops/">Workshops</a>
          </li>
          <li>
            <a class="plainButton" target="_blank" href="https://achcu.com/webinars/">Webinars</a>
          </li>
          <li>
            <a class="plainButton" target="_blank" href="https://www.achc.org/events/">Events</a>
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-card>
</template>
<style lang="scss" scoped>
p {
  font-size: 0.65rem;
  color: #737373;
  margin: 0;
}
a {
  font-size: 0.7rem;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
}
img {
  height: 75px;
}
.achcuLinks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 7px;
}
.base-icon-light {
  width: 60px;
  height: 60px;
  padding: 0;
}
.achc-menu {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 0;
}

.achc-menu li {
  text-align: center;
  flex-grow: 1;
  position: relative;
  border-right: 1px #d2d2d2 solid;
}
.achc-menu li:last-child {
  border-right: none;
}

.plainButton {
  cursor: pointer;
  background-color: transparent;
  color: #737373;
  border: none;
  font-size: 10px;
  font-weight: 600;
  transition: color 350ms ease;
  text-decoration: none;
}

.plainButton:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 15%;
  right: 0;
  width: 70%;
  height: 2px;
  background-color: #005175;
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
  transition: opacity 300ms, transform 300ms;
}

.plainButton:hover,
.plainButton:focus {
  color: #005175;
}

.plainButton:hover::after,
.plainButton:focus::after {
  opacity: 1;
  transform: scale(1);
}
</style>
<script>
export default {};
</script>
