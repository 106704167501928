<script>
import axios from "axios";
import { WebAPIUrl } from "../constants/config";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      applicationDashboard: {
        inProgress: 0,
        missingItems: 0,
        notSubmitted: 0,
        completed: 0,
        applications: [],
      },
      applicationID: 0,
      statusCode: null,
      availableLocations: [],
      loading: false,
    };
  },
  methods: {
    ...mapMutations([
      "setDangerMessage",
      "setAlertMessage",
      "setInfoMessage",
      "appendInfoMessage",
      "appendDangerMessage",
      "setApplicationsData",
    ]),
    async getApplicationDashboardInfo(companyID) {
      await axios
        .get(`${WebAPIUrl}/api/application/${companyID}/dashboard`)
        .then((result) => {
          this.applicationDashboard = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async startApplication(companyID) {
      await axios
        .post(`${WebAPIUrl}/api/application/${companyID}`)
        .then((result) => {
          this.applicationID = result.data.applicationID;
          this.statusCode = result.status;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getApplications(companyId) {
      await axios
        .get(`${WebAPIUrl}/api/application/${companyId}/table`)
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) {
            this.setApplicationsData(result.data.data);
          }
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async deleteApplication(applicationId) {
      await axios
        .delete(`${WebAPIUrl}/api/application/${applicationId}`)
        .then((result) => {
          this.statusCode = result.status;
          if (this.statusCode === 200) this.setInfoMessage(result.data);
        })
        .catch((error) => {
          this.setDangerMessage(error);
        });
    },
    async getAvailableLocations(applicationId) {
      this.loading = true;
      await axios
        .get(`${WebAPIUrl}/api/application/availablelocations/${applicationId}`)
        .then((result) => {
          this.availableLocations = result.data;
        })
        .catch((error) => {
          this.setDangerMessage(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
