<template>
  <b-card title="News from ACHC" class="dashboard-card shadow-sm">
    <div
      v-for="article in recentNewsList"
      :key="article.index"
      @click="viewArticle(article)"
      class="articleBlock"
    >
      <div class="featuredArticleBlock">
        <p class="subjectText">{{ article.categories.join(", ") }}</p>
        <p class="programText">{{ article.title }}</p>
        <p class="bodyText">{{ article.contentSnippet }}</p>
        <hr v-if="separatorVisible(article)" />
      </div>
    </div>
    <plain-button v-if="canViewMoreNews" @click="viewMoreNews" class="mt-2 mb-2"
      >View More News</plain-button
    >
  </b-card>
</template>
<style scoped>
.programText {
  /* text-transform: uppercase; */
  color: #005175;
  font-weight: 700;
  font-size: 0.65rem;
  margin: 0;
}
.subjectText {
  color: #737373;
  font-weight: 700;
  font-size: 1rem;
  margin: 0;
  transition: color 0.2s ease;
}
.bodyText {
  color: #979797;
  font-size: 0.7rem;
  margin: 0;
}
.featuredArticleBlock {
  display: flex;
  flex-direction: column;
  margin: 0 8px;
}
.articleBlock {
  cursor: pointer;
}
.articleBlock:hover > .featuredArticleBlock > .subjectText {
  color: #f7ef99;
}
.bodyText {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
<script>
import { appRoot } from "../../../constants/config";
import PlainButton from "../../Common/PlainButton.vue";

let Parser = require("rss-parser");

export default {
  props: {
    maxArticles: {
      default: 0,
    },
  },
  components: {
    "plain-button": PlainButton,
  },
  data() {
    return {
      recentNews: [],
    };
  },
  // rss-parser - Node Js
  async mounted() {
    let parser = new Parser();
    let feed = await parser.parseURL("https://www.achc.org/feed/");

    feed.items.forEach(() => {
      this.recentNews = feed.items;
    });
  },
  methods: {
    viewArticle(article) {
      window.open(article.link, "_blank");
    },
    viewMoreNews() {
      this.$router.push(`${appRoot}/news`);
    },
    separatorVisible(article) {
      if (this.recentNewsList[this.recentNewsList.length - 1] === article) {
        return false;
      }
      return true;
    },
  },
  computed: {
    recentNewsList() {
      return this.maxArticles > this.recentNews.length
        ? this.recentNews
        : this.recentNews.slice(0, this.maxArticles);
    },
    canViewMoreNews() {
      if (!this.recentNews || this.maxArticles === 0) {
        return false;
      }
      return this.recentNews.length > this.maxArticles;
    },
  },
};
</script>
