<template>
  <!-- I hate having 2 grouping containers
  but unfortunately this is the only way to
  constrain the underline to just use the width
  of the button element -->
  <div class="mainContainer">
    <div class="buttonContainer">
      <a class="plainButton" @click.self="clickEventFired"><slot></slot></a>
    </div>
  </div>
</template>
<style scoped>
.mainContainer {
  display: flex;
  justify-content: center;
}
.buttonContainer {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 0 auto;
}
.plainButton {
  cursor: pointer;
  background-color: transparent;
  color: #737373;
  border: none;
  font-size: 0.85rem;
  font-weight: 600;
  transition: color 350ms ease;
  text-decoration: none;
}

.plainButton:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 15%;
  right: 0;
  width: 70%;
  height: 2px;
  background-color: #005175;
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
  transition: opacity 300ms, transform 300ms;
}

.plainButton:hover,
.plainButton:focus {
  color: #005175;
}

.plainButton:hover::after,
.plainButton:focus::after {
  opacity: 1;
  transform: scale(1);
}
</style>
<script>
export default {
  methods: {
    clickEventFired() {
      this.$emit("click");
    },
  },
};
</script>
